import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { RequestedOrientationDto, RequestedShiftDto } from '@clh/api-client';
import { Alert, Spinner, useApiClient, useError } from '@clh/ui';

import { CalendarView } from '../hooks/use-calendar-view';
import { useSession } from '../hooks/use-session';
import { Breadcrumb, Breadcrumbs } from '../shared-components/breadcrumbs';
import Button from '../shared-components/button';
import CalendarPicker from '../shared-components/calendar-picker';

import CalendarLegend from './calendar-legend';

const MyAvailability = () => {
    const api = useApiClient();
    const { currentUser } = useSession();
    const navigate = useNavigate();
    const [availableDates, setAvailableDates] = useState<{
        [date: string]: boolean;
    }>({});
    const [availableDatesError, setAvailableDatesError] = useError();
    const [orientations, setOrientations] = useState<RequestedOrientationDto[]>(
        []
    );
    const [orientationsError, setOrientationsError] = useError();
    const [shifts, setShifts] = useState<RequestedShiftDto[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [shiftsError, setShiftsError] = useError();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useError();

    const [searchParams] = useSearchParams();

    const calendarPickerDateFromParams = searchParams.get('calendarPickerDate');

    const fetchOrientations = async () => {
        try {
            const response = await api?.nurseControllerListOrientations({
                profileId: currentUser!.id,
                pageSize: 100,
            });

            if (response) {
                setOrientations(response.result);
            }
        } catch (e) {
            setOrientationsError(e, 'Unable to load orientations');
        }
    };

    const fetchShifts = async () => {
        try {
            const shifts = await api?.nurseControllerListShifts({
                profileId: currentUser!.id,
                pageSize: 100,
            });

            if (shifts) {
                setShifts(shifts.result);
            }
        } catch (e) {
            setShiftsError(e, 'Unable to load shifts');
        }
    };

    const fetchAvailability = async (after?: Date, before?: Date) => {
        try {
            const a = await api?.scheduleControllerGetAvailability({
                nurseProfileId: currentUser!.id,
                after,
                before,
            });

            if (a) {
                setAvailableDates(
                    a.reduce<{
                        [date: string]: boolean;
                    }>((acc, d) => {
                        acc[d.slice(0, 10)] = true;
                        return acc;
                    }, {})
                );
            }
        } catch (e) {
            setAvailableDatesError(e);
        }
    };

    const submitAvailability = () => {
        setIsSubmitting(true);

        api
            ?.scheduleControllerSetAvailability({
                nurseProfileId: currentUser!.id,
                scheduleAvailabilityDto: {
                    dates: Object.entries(availableDates).map(
                        ([key, value]) => ({
                            date: new Date(key),
                            available: value,
                        })
                    ),
                },
            })
            .then(() => {
                navigate('/site/my-schedule');
            })
            .catch((e) => {
                setSubmitError(e);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        if (currentUser?.id) {
            void Promise.all([
                fetchOrientations(),
                fetchShifts(),
                fetchAvailability(),
            ]).finally(() => {
                setIsLoading(false);
            });
        }
    }, [currentUser?.id]);

    useEffect(() => {
        if (currentUser?.id && calendarPickerDateFromParams) {
            const dateTime = DateTime.fromFormat(
                calendarPickerDateFromParams,
                'yyyy-MM-dd'
            );

            void fetchAvailability(
                dateTime.startOf('month').minus({ week: 1 }).toJSDate(),
                dateTime.endOf('month').plus({ week: 1 }).toJSDate()
            );
        }
    }, [currentUser?.id, calendarPickerDateFromParams]);

    const datesWithEvents = useMemo(() => {
        if (isLoading) {
            return new Set([]);
        }

        const orientationDates = orientations.flatMap((orientation) => {
            return orientation.orientationSessions.map((s) =>
                DateTime.fromJSDate(s.startTime).toFormat('yyyy-MM-dd')
            );
        });

        const shiftDates = shifts.map((shift) => {
            return DateTime.fromJSDate(shift.startTime).toFormat('yyyy-MM-dd');
        });

        return new Set([...orientationDates, ...shiftDates]);
    }, [orientations, shifts]);

    const errors = [availableDatesError, shiftsError, orientationsError].filter(
        Boolean
    );

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <Breadcrumbs>
                    <Breadcrumb to="/site/profile/list">Home</Breadcrumb>
                    <Breadcrumb to="/site/my-schedule">My Schedule</Breadcrumb>
                    <Breadcrumb isActive>My Availability</Breadcrumb>
                </Breadcrumbs>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1 className="h3 mb-0">My Availability</h1>
                </div>
            </div>
            {errors.length ? (
                <Alert className="m-2" message={errors} />
            ) : (
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-xl-6">
                        <Alert message={submitError} />
                        <div className="row text-center justify-content-center mb-3">
                            <div className="col-md-9 px-5">
                                <p>
                                    Select the days you are available to work.
                                    Please confirm that your calendar is correct
                                    for the next three weeks.
                                </p>
                                <Button
                                    onClick={submitAvailability}
                                    isLoading={isSubmitting}
                                >
                                    Confirm Availability
                                </Button>
                            </div>
                        </div>
                        <div className="bg-light p-3 pb-1 mb-3">
                            <CalendarPicker
                                view={CalendarView.MONTH}
                                seedDate={new Date()}
                                minDate={DateTime.now()
                                    .startOf('day')
                                    .toJSDate()}
                                selectedDates={
                                    new Set(
                                        Object.entries(availableDates).reduce<
                                            string[]
                                        >((acc, [key, value]) => {
                                            if (value === true) {
                                                acc.push(key);
                                            }

                                            return acc;
                                        }, [])
                                    )
                                }
                                datesWithEvents={datesWithEvents}
                                onSelect={(d) => {
                                    const formattedDate =
                                        DateTime.fromJSDate(d).toFormat(
                                            'yyyy-MM-dd'
                                        );

                                    setAvailableDates((dates) => ({
                                        ...dates,
                                        [formattedDate]:
                                            typeof dates[formattedDate] ===
                                            'boolean'
                                                ? !dates[formattedDate]
                                                : true,
                                    }));
                                }}
                                onTodayClickOverride={() => {}} // do nothing
                            />
                        </div>
                        <CalendarLegend />
                    </div>
                </div>
            )}
        </>
    );
};

export default MyAvailability;
