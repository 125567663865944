import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@clh/ui';

const LogIn: React.FC<{
    authFlow?: 'login' | 'signup';
    redirectToCurrentPage?: boolean;
}> = function SignIn({ authFlow = 'login', redirectToCurrentPage }) {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        // Check to see if we are already logged in.
        if (isAuthenticated) {
            navigate('/site/post-sign-in', { replace: true });
        } else {
            if (redirectToCurrentPage) {
                sessionStorage.setItem('pathname', window.location.pathname);
                sessionStorage.setItem('search', window.location.search);
                sessionStorage.setItem('hash', window.location.hash);
            }

            void loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/site/post-sign-in`,
                    screen_hint: authFlow,
                },
                async openUrl(url) {
                    window.location.replace(url);
                },
            });
        }
    }, [isAuthenticated, isLoading]);

    return <Spinner />;
};

export default LogIn;
