import classNames from 'classnames';
import { DateTime } from 'luxon';

const LastEdited = ({
    className,
    lastEditedAt,
}: {
    className?: string;
    lastEditedAt: Date;
}) => {
    return (
        <div className={classNames('text-secondary', className)}>
            <i>
                Edited on{' '}
                {DateTime.fromJSDate(lastEditedAt).toFormat(
                    'MM/dd/yyyy HH:mm ZZZZ'
                )}
            </i>
        </div>
    );
};

export default LastEdited;
