import Quill from 'quill';
export var getHtmlFromQuillDeltas = function (mightBeDelta) {
    if (mightBeDelta) {
        if (mightBeDelta.startsWith('[') && mightBeDelta.endsWith(']')) {
            var el = document.createElement('div');
            var q = new Quill(el);
            q.setContents(JSON.parse(mightBeDelta));
            var html = q.root.innerHTML;
            el.remove();
            return html;
        }
        return mightBeDelta;
    }
    return '';
};
