import { Auth0Provider } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import {
    AccessTokenProvider,
    ConfigProvider,
    RolesProvider,
    useAccessToken,
} from '@clh/ui';
import { makeWebappClient } from '@clh/web-client';

import { App } from './app';
import { ActionNeededProvider } from './hooks/use-action-needed';
import { CourierAuthTokenProvider } from './hooks/use-courier-auth-token';
import { FacilityProvider } from './hooks/use-facility';
import { FeatureFlagsProvider } from './hooks/use-feature-flags';
import { SessionProvider } from './hooks/use-session';
import { newRelic } from './newRelic';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './index.less';

const root = createRoot(document.getElementById('root') as HTMLElement);

const NewRelic = () => {
    const { auth0UserId, clhSessionId } = useAccessToken();

    useEffect(() => {
        newRelic.setApplicationVersion(GIT_COMMIT);
    }, [GIT_COMMIT]);

    useEffect(() => {
        if (auth0UserId) {
            newRelic.setCustomAttribute('auth0UserId', auth0UserId, true);
        }
    }, [auth0UserId]);

    useEffect(() => {
        if (clhSessionId) {
            newRelic.setCustomAttribute('clhSessionId', clhSessionId, true);
        }
    }, [clhSessionId]);

    return null;
};

async function main() {
    const apiClient = makeWebappClient({});

    const { config } = await apiClient.bootstrapControllerGetBootstrapConfig();

    root.render(
        <Auth0Provider
            domain={config.auth0TenantDomain}
            clientId={config.webappAuth0ClientId}
            cacheLocation="localstorage"
            authorizationParams={{
                audience: config.apiAuth0Audience,
                redirect_uri: `${window.location.origin}/site/post-sign-in`,
            }}
            useRefreshTokens
        >
            <ConfigProvider config={config}>
                <AccessTokenProvider
                    audience={config.apiAuth0Audience}
                    redirectUri={`${window.location.origin}/site/post-sign-in`}
                >
                    <RolesProvider>
                        <SessionProvider>
                            <CourierAuthTokenProvider>
                                <FeatureFlagsProvider>
                                    <FacilityProvider>
                                        <ActionNeededProvider>
                                            <NewRelic />
                                            <App />
                                        </ActionNeededProvider>
                                    </FacilityProvider>
                                </FeatureFlagsProvider>
                            </CourierAuthTokenProvider>
                        </SessionProvider>
                    </RolesProvider>
                </AccessTokenProvider>
            </ConfigProvider>
        </Auth0Provider>
    );
}

void main();

if (module.hot) {
    module.hot.accept('./app.tsx', function () {
        void main();
    });
}
