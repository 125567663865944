import { createContext } from 'react';

export interface FeatureFlagsContextValue {
    RATE_CALCULATOR?: boolean;
    AI_VACCINATION_DOCUMENTS?: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(
    undefined as any
);
