import { PropsWithChildren, useEffect, useState } from 'react';

import { useApiClient, useConfig } from '@clh/ui';

import { newRelic } from '../../newRelic';
import { useSession } from '../use-session';

import {
    FeatureFlagsContext,
    FeatureFlagsContextValue,
} from './feature-flags-context';

export const FeatureFlagsProvider: React.FC<PropsWithChildren> =
    function FeatureFlagsProvider(props: PropsWithChildren) {
        const { currentUser } = useSession();
        const config = useConfig();
        const api = useApiClient();
        const [flags, setFlags] = useState({});

        const fetchFlags = async () => {
            const flagsV2 = currentUser
                ? await api?.featureFlagControllerGetUserFeatureFlags({
                      profileId: currentUser.id,
                  })
                : await api?.featureFlagControllerGetCurrentUserFeatureFlags();

            const flags =
                [
                    ...(config.featureFlags || []),
                    ...(flagsV2?.featureFlags || []),
                ]?.reduce<FeatureFlagsContextValue>((acc, f) => {
                    acc[f as keyof FeatureFlagsContextValue] = true;
                    return acc;
                }, {}) || {};

            return setFlags(flags);
        };

        useEffect(() => {
            if (api?.isAuthenticated) {
                fetchFlags().catch(newRelic.noticeError);
            }
        }, [config.featureFlags, currentUser, api?.isAuthenticated]);

        return (
            <FeatureFlagsContext.Provider value={flags}>
                {props.children}
            </FeatureFlagsContext.Provider>
        );
    };
