import { useEffect } from 'react';
import { useParams } from 'react-router';

import { Spinner } from '@clh/ui';

export const Impersonate = ({ stop }: { stop?: boolean }) => {
    const { profileId } = useParams();

    useEffect(() => {
        if (stop) {
            sessionStorage.removeItem('currentUserId');
        } else if (profileId) {
            sessionStorage.setItem('currentUserId', profileId);
        }

        // Perform a full page refresh so that the session provider hook reruns
        window.location.href = `/`;
    }, []);

    return <Spinner />;
};
